import {
    authedAxiosInstance
} from "./api.service";

const UserService = {
    update_address: async (address) => {
        const response = await authedAxiosInstance.post("/settings/address", address);
        return response.data;
    },
    update_user_picture: async (picture) => {
        const formData = new FormData();
        formData.append("picture", picture.picture);
        const response = await authedAxiosInstance.post("/settings/user-picture", formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        return response.data;
    },
};

export default UserService;