import React, { Fragment, useState } from "react";
import logo from "../assets/images/bizooy-logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validateEmail } from "../utils/validation";
import AuthService from "../api/auth.service";

const ResetPwdWithEmail = () => {
  const [email, setEmail] = useState("");

  const forgotPass = async (e) => {
    e.preventDefault();
    let isEmailVaild = validateEmail(email);
    if (!isEmailVaild) {
      await AuthService.reset_password({ email: email })
        .then(() => {
          toast.success("Email sent successfully. Check your inbox.", {
            position: "top-center",
            autoClose: 5000,
          });
          setEmail("");
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-center",
            autoClose: 5000,
          });
        });
    } else {
      toast.error("Invalid email.", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* <!-- Reset Password page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12 p-0">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} alt="" />
                    </div>
                    <div className="card mt-4 p-4">
                      <form className="theme-form">
                        <h6 className="f-16 text-center">
                          Enter your email address to reset your password.{" "}
                        </h6>
                        <div className="form-group mt-4">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Enter your email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </div>
                        <div className="form-group text-center">
                          <div className="d-inline-block form-group mb-0">
                            <div className="col-md-2">
                              <button
                                className="btn btn-primary"
                                type="submit"
                                onClick={(e) => forgotPass(e)}
                              >
                                Reset Password
                              </button>
                            </div>
                          </div>
                          <div className="d-inline-block form-group mb-0">
                            <div className="col-md-2">
                              <button className="btn" type="reset">
                                <a
                                  className="btn-link text-capitalize"
                                  href="/login"
                                >
                                  Back
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- Reset Password page end--> */}
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPwdWithEmail;
