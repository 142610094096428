
import React, { useEffect, useState } from "react";
import MailchimpService from '../../api/mailchimp.service';
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import "react-toastify/dist/ReactToastify.css";

const MailchimpCustomers = ({ id }) => {

    const [customers, setCustomers] = useState(null);

    useEffect(() => {
        async function getCustomers() {
            const customers = await MailchimpService.customers(id);
            setCustomers(customers.customers);
            console.log(customers.customers);
        };

        getCustomers();
    }, [id]);

    const importCustomer = async  (customer) => {

        try{
            await MailchimpService.add({customer: customer});
            toast.success("Customer imported successfully.", {
                position: "top-right",
                autoClose: 5000,
              });
        }
        catch(e){
            toast.error(e.message, {
                position: "top-right",
                autoClose: 5000,
              });
        }

        

    };

    return (
        <div className="table-responsive">
            <div className="card">
                <table className="table table-striped" style={{maxHeight: 150}}>
                    <thead className="table-success">
                        <tr className="text-center">
                            <th scope="col">Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Source</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>

                    <tbody>
                        {customers &&
                            customers.map((customer, index) => {
                                return (
                                    <tr key={index} >
                                        <td>{customer.merge_fields.FNAME} {customer.merge_fields.LNAME}</td>
                                        <td>
                                        <span className="badge badge-success">{customer.status}</span>
                                        </td>
                                        <td>
                                        {customer.source}
                                        </td>
                                        <td>
                                            <Button className="btn btn-success" onClick={() => importCustomer(customer)}>Import contact</Button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );

};

export default MailchimpCustomers;