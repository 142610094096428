import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import BizooyCard from "./bizooycard";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};

const Payment = () => {
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

  return (
    <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
      <BizooyCard />
    </Elements>
  );
};

export default Payment;
