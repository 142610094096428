import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import seven from '../../assets/images/user/7.jpg';
import UserService from '../../api/user.service';
import { toast } from "react-toastify";
import { useSelector, useDispatch } from 'react-redux';
import ImageUploader from 'react-images-upload';
import { setUserPicture } from "../../store/loggedInUser.slice";

const UserEdit = () => {

    const { url, email, username, address, status, subscription_type } = useSelector((state) => state.authUser);

    let dispatch = useDispatch();

    const [picture, setPicture] = useState('');

    const handleSubmit = async (event) => {

        event.preventDefault();

        try {
            await UserService.update_address({
                address: {
                    street: event.target.street.value,
                    state: event.target.state.value,
                    city: event.target.city.value,
                    zip: event.target.zip.value,
                    country: event.target.country.value
                }
            });
            toast.success("Address information updated successfully.", {
                position: "top-right",
                autoClose: 5000,
            });
        }
        catch (e) {
            toast.error(e.message, {
                position: "top-right",
                autoClose: 5000,
            });
        }

    };

    useEffect(() => {
        setPicture(url ? url : seven);
      }, [url]);

    const onDrop = async (pictureFiles, pictureDataURLs) => {
        try {

            await UserService.update_user_picture({ picture: pictureFiles[0] }).then((picture_url) => {
                setPicture(picture_url.picture_url);
                dispatch(setUserPicture(picture_url.picture_url));
                toast.success("User picture updated successfully.", {
                    position: "top-right",
                    autoClose: 5000,
                });
            });

        }
        catch (e) {
            toast.error(e.message, {
                position: "top-right",
                autoClose: 5000,
            });
        }

    }

    return (
        <Fragment>
            <Breadcrumb parent="User" title="Edit Profile" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><span className="badge badge-primary">My Profile</span></h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="row mb-2">
                                            <div className="col-auto">
                                                <img className="img-70 rounded-circle" alt="" src={picture} />
                                            </div>
                                            <div className="col">
                                                <h3 className="mb-1">{username}</h3>
                                                <p className="mb-4">{email}</p>
                                                <p className="mb-4"><span className="badge badge-success">{status}</span></p>
                                                <p className="mb-4"><span className="badge badge-warning">{subscription_type}</span></p>
                                            </div>
                                        </div>
                                        <div className="form-footer">
                                            <ImageUploader
                                                withIcon={false}
                                                withPreview={false}
                                                label=""
                                                onChange={onDrop}
                                                singleImage={true}
                                                buttonText="Update User Picture"
                                                imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg", ".jpeg"]}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><span className="badge badge-primary">Address Information</span></h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    {address &&
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Street</label>
                                                    <input className="form-control" type="text" placeholder="Street" name="street" defaultValue={address.street} />
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label">State</label>
                                                    <input className="form-control" type="text" placeholder="State" name="state" defaultValue={address.state} />
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label">City</label>
                                                    <input className="form-control" type="text" placeholder="City" name="city" defaultValue={address.city} />
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label">Postal Code</label>
                                                    <input className="form-control" type="number" placeholder="ZIP Code" name="zip" defaultValue={address.zip} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label">Country</label>
                                                    <select className="form-control btn-square" name="country" defaultValue={address.country}  >
                                                        <option defaultValue="USA">USA</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-primary" type="submit">Update Address</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default UserEdit;