import {
    authedAxiosInstance
} from "./api.service";

const MailchimpService = {
    lists: async () => {
        const response = await authedAxiosInstance.get("/mailchimp/lists");
        return response.data;
    },
    customers: async (id) => {
        const response = await authedAxiosInstance.get(`/mailchimp/lists/${id}/customers`);
        return response.data;
    },
    add: async(customer) => {
        const response = await authedAxiosInstance.post("/mailchimp/add", customer);
        return response.data;
    },
    sync: async (id) => {
        const response = await authedAxiosInstance.post(`/mailchimp/sync/${id}`);
        return response.data;
    },
};

export default MailchimpService;