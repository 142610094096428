import React from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import FeedbackService from "../../api/feedback.service";

const Unsubscribe = () => {

  const todosPerPage = 7;
  const [activePage, setCurrentPage] = useState(1);
  const [unsubscribe, setUnsubscribe] = useState(1);

  const indexOfLastTodo = activePage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const dispatch = useDispatch();

  useEffect(() => {

    async function populate() {
      const reviews = await FeedbackService.unsubscribed();
      setUnsubscribe(reviews.customers);
    }

    populate();
  }, [dispatch]);

  return (
    <div>
  {unsubscribe &&
      <div className="container-fluid vh-100">
        <div className="row">
          <div className="col-sm-12 p-0">
            <div className="card mt-4 p-4">
              <div>
                <h4 className=""><span className="badge badge-primary">Unsubscribed</span></h4>
                <hr />
                {unsubscribe.length > 0 ? (

                  <div>

                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead className="table-primary">
                          <tr className="text-center">
                            <th scope="col">Name</th>
                            <th scope="col">Date Unsubscribed</th>
                          </tr>
                        </thead>

                        <tbody>
                          {unsubscribe.length > 0 && 
                          
                          unsubscribe
                            .slice(indexOfFirstTodo, indexOfLastTodo)
                            .map((customer) => (
                              <tr className="text-center" key={customer.key}>
                                <td>{customer.name}</td>
                                <td>{customer.date_unsubscribed}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="text-center float-right mt-4">
                      <Pagination
                        activePage={activePage}
                        totalItemsCount={unsubscribe.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First"
                        lastPageText="Last"
                      />
                    </div>

                  </div>


                ) : (
                    <h4 className="text-center notAvailableTextStyle">
                      No unsubscribed customers available.
          </h4>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    </div>
  );
};

export default Unsubscribe;
