import React, { useState, useEffect } from "react";
import "../dashboard/dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SettingsService from "../../api/settings.service";
import { setSocialProfile } from "../../store/loggedInUser.slice";

const SocialProfiles = () => {

  const { social_profile } = useSelector((state) => state.authUser);

  const [social, setSocial] = useState('');

  let dispatch = useDispatch();

  const default_profile = {
      "facebook": {
        "url": ""
      },
      "google": {
        "url": ""
      },
      "yelp": {
        "url": ""
      },
      "custom": {
        "url": "",
        "name": ""
      }
  };

  useEffect(() => {
    setSocial(social_profile == null ? default_profile : social_profile);
  }, [social_profile, default_profile]);

  const handleSubmit = async (event) => {

    event.preventDefault();

    try {
      const social_profile = {
        facebook: {
          url: event.target.facebook.value
        },
        google: {
          url: event.target.google.value
        },
        yelp: {
          url: event.target.yelp.value
        },
        custom: {
          url: event.target.custom_link.value,
          name: event.target.custom_website_name.value
        },
      };

      if ((social_profile.custom.url !== "" && social_profile.custom.name === "") || (social_profile.custom.name !== "" && social_profile.custom.url === "")) {
        throw new Error("Please fill all the custom fields.");
      }

      await SettingsService.update_social_media({ social_profile: social_profile });
      dispatch(setSocialProfile(social_profile));
      setSocial(social_profile);
      toast.success("Profile updated successfully.", {
        position: "top-right",
        autoClose: 5000,
      });

    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <div
      className="container-fluid">
      <div className="row">
        <div className="col-sm-12 p-0">
          <div className="card mt-4 p-4">
            <h4 className=""><span className="badge badge-primary">Social Profiles</span></h4>
            <p>Enter the social media links to redirect customers to your review platform.</p>
            <hr />
            <form className="theme-form"
              onSubmit={
                handleSubmit
              }>
              {social &&
                <div className="row">
                  <div className="col-md-12">

                    <div className="form-group">
                      <label className="form-label"><span className="badge badge-light">Facebook</span> - <span className={social.facebook.url !== "" ? 'badge badge-success' : 'badge badge-danger'}>{social.facebook.url !== "" ? 'ACTIVE' : 'INACTIVE'}</span></label>
                      <input className="form-control" type="url" pattern="https://.*" name="facebook" defaultValue={social.facebook.url} />
                    </div>

                    <div className="form-group">
                      <label className="form-label"><span className="badge badge-light">Google</span> - <span className={social.google.url !== "" ? 'badge badge-success' : 'badge badge-danger'}>{social.google.url !== "" ? 'ACTIVE' : 'INACTIVE'}</span></label>
                      <input className="form-control" type="url" pattern="https://.*" name="google" defaultValue={social.google.url} />
                    </div>

                    <div className="form-group">
                      <label className="form-label"><span className="badge badge-light">Yelp</span> - <span className={social.yelp.url !== "" ? 'badge badge-success' : 'badge badge-danger'}>{social.yelp.url !== "" ? 'ACTIVE' : 'INACTIVE'}</span></label>
                      <input className="form-control" type="url" pattern="https://.*" name="yelp" defaultValue={social.yelp.url} />
                    </div>

                    <div className="form-group">
                      <p><span className="badge badge-light">Custom</span> - <span className={social.custom.url !== "" ? 'badge badge-success' : 'badge badge-danger'}>{social.custom.url !== "" ? 'ACTIVE' : 'INACTIVE'}</span></p>
                      <label className="form-label">Name</label>
                      <input className="form-control" type="text" name="custom_website_name" defaultValue={social.custom.custom_website_name} />
                      <label className="form-label">Link</label>
                      <input className="form-control" type="url" pattern="https://.*" name="custom_link" defaultValue={social.custom.url} />
                    </div>

                    <div className="card-footer">
                      <button className="btn btn-primary" type="submit">Update</button>
                    </div>

                  </div>
                </div>
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialProfiles;
