import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {stateFromHTML} from 'draft-js-import-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch } from "react-redux";
import SettingService from "../../api/settings.service";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {setEmailTemplate} from "../../store/loggedInUser.slice";

const EmailEditor = ({email}) => {

  // const { url } = useSelector((state) => state.authUser);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {

    setEditorState(editorState);
      draftToHtml(convertToRaw(editorState.getCurrentContent()));
    
  };



  useEffect(() => {
    // automatedMessage is the html string I am fetching from my server
     if (email) {
       let contentState = stateFromHTML(email); //automatedMessage.message being "<p>Test</p>"
       setEditorState(EditorState.createWithContent(contentState));
     }
   }, [email]);

  let dispatch = useDispatch();

  const emailstate = async (e) => {
    e.preventDefault();
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    await SettingService.update_email_template({template: html});
      toast.success("Custom email updated successfully.", {
        position: "top-right",
        autoClose: 5000,
      });
      dispatch(setEmailTemplate(html));
  };

  return (
      <div className="editor mx-4 mt-4 text-right">
            <h4 className=""><span className="badge badge-primary">Custom Email Message</span></h4>
            
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          onEditorStateChange={onEditorStateChange}
        />
      
      <form>
        <textarea
          className="demo-content no-focus"
          disabled
          id="template"
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
          name="template"
        />

        <button
          type="button"
          className="btn btn-primary mx-2"
          onClick={(e) => emailstate(e, "email")}
        >
          Save
        </button>

       
      </form>
      </div>
  );
};

export default EmailEditor;
