import React, { useState, Fragment } from "react";
import "react-dropzone-uploader/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import sample from "../../sample.csv";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomerService from "../../api/customer.service";

const CustomerBulk = () => {

  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Choose File");

  const onChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const customerbulk = async (e) => {
    e.preventDefault();
    try {
      toast.info("Processing your request.", {
        position: "top-right",
        autoClose: 5000,
      });
      await CustomerService.bulk({ file: file });
      toast.success("Customers in bulk added succesfully.", {
        position: "top-right",
        autoClose: 5000,
      });
      setFile("");
      setFilename("");
    }
    catch (e) {
      toast.error(e.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <Fragment>
      <div
        className="container-fluid" >
        <div className="row">
          <div className="col-sm-12 p-0">
            <div className="card mt-4 p-4">
              <h4 className=""><span className="badge badge-primary">Add Customers in Bulk</span></h4>
              <hr />

              <form method="post" encType="multipart/form-data">
                <div>
                  <div className="row">
                    <div className="mx-2 col custom-file">
                      <input
                        type="file"
                        name="file"
                        accept=".csv"
                        className="custom-file-input"
                        id="customFile"
                        onChange={onChange}
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        {filename}
                      </label>
                      <small>Please just upload CSV file*</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col my-2 mt-4">
                      <h6>
                        <a
                          download="sample.csv"
                          rel="noopener noreferrer"
                          target="_blank"
                          href={sample}
                        >
                          <i className="fa fa-download"></i> Download the sample here.</a>

                      </h6>
                    </div>
                  </div>
                  <div className="text-center my-4">
                    <button

                      className="btn-lg btn-primary text-center"
                      type="submit"
                      onClick={(e) => customerbulk(e, "success")}
                      disabled={!file}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerBulk;
