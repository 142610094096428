import {
    authedAxiosInstance
} from "./api.service";

const FeedbackService = {
    request_review: async () => {
        const response = await authedAxiosInstance.get("/customers/status/request%20review");
        return response.data;
    },
    unopened: async () => {
        const response = await authedAxiosInstance.get("/customers/status/unopened");
        return response.data;
    },
    opened: async () => {
        const response = await authedAxiosInstance.get("/customers/status/opened");
        return response.data;
    },
    completed_review: async () => {
        const response = await authedAxiosInstance.get("/customers/status/completed%20review");
        return response.data;
    },
    unsubscribed: async () => {
        const response = await authedAxiosInstance.get("/customers/status/unsubscribed");
        return response.data;
    },
    request_feedback: async (customers) => {
        const response = await authedAxiosInstance.post("/feedback/send", customers);
        return response.data;
    },
};

export default FeedbackService;