import React from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { deleteRequest } from "../../actions/review.action";
import Pagination from "react-js-pagination";
import FeedbackService from "../../api/feedback.service";

const Unopened = () => {

  const todosPerPage = 10;
  const [activePage, setCurrentPage] = useState(1);
  const [unopened, setUnopened] = useState([]);

  const indexOfLastTodo = activePage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const dispatch = useDispatch();


  useEffect(() => {

    async function populate() {
      const reviews = await FeedbackService.unopened();
      setUnopened(reviews.customers);
    }

    populate();
  }, [dispatch]);

  const onDelete = (key) => {
    dispatch(deleteRequest(key));
  };

  return (
    <div>
      {unopened &&
        <div className="container-fluid vh-100">
          <div className="row">
            <div className="col-sm-12 p-0">
              <div className="card mt-4 p-4">
                <div>
                  <h4 className=""><span className="badge badge-primary">Unopened</span></h4>
                  <hr />
                  {unopened.length > 0 ? (

                    <div>

                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead className="table-primary">
                            <tr className="text-center">
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Phone Number</th>
                              <th scope="col">Request Type</th>
                              <th scope="col">Date Request Sent</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>

                          <tbody>
                            {unopened.slice(indexOfFirstTodo, indexOfLastTodo)
                              .map((customer) => (
                                <tr className="text-center" key={customer.key}>
                                  <td>{customer.name}</td>
                                  <td>{customer.email}</td>
                                  <td>{customer.phone}</td>
                                  <td>
                                    {!!(customer.request.email_id) &&
                                      <span className="badge badge-warning mr-2 ml-2"> <i
                                        className='fa fa-envelope'></i> </span>
                                    }
                                    {!!(customer.request.sms_id) &&
                                      <span className="badge badge-success mr-2 ml-2"><i
                                        className='fa fa-phone'></i></span>
                                    }
                                  </td>
                                  <td>{customer.date_request_sent}</td>
                                  <td>
                                    <i
                                      className="fa fa-trash trash"
                                      onClick={(key) => onDelete(customer.key)}
                                    ></i>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="text-center float-right mt-4">
                        <Pagination
                          activePage={activePage}
                          totalItemsCount={unopened.length}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                          firstPageText="First"
                          lastPageText="Last"
                        />
                      </div>

                    </div>


                  ) : (
                      <h4 className="text-center notAvailableTextStyle">
                        No unopened feedback available.
            </h4>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );

};

export default Unopened;
