import React from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

const Bargraph = ({ profiles }) => {

  const dispatch = useDispatch();

  const [data, setData] = useState(null);

  useEffect(() => {
    
    const chart_data = {
      labels: ["Facebook", "Yelp", "Google", "Custom"],
      datasets: [
        {
          label: "Reviews per Social Profile", 
          backgroundColor: ["rgba(0, 0, 205, 0.1)", "rgba(255, 86, 48, 0.11)", "rgba(7, 191, 88, 0.1)", "rgba(239, 203, 113, 1)"],
          borderColor: ["rgba(0, 0, 205, 0.1)", "rgba(255, 86, 48, 0.11)", "rgba(7, 191, 88, 0.1)", "rgba(239, 203, 113, 1)"],
          borderWidth: 1,
          hoverBackgroundColor: ["rgba(0, 0, 205, 1)", "rgba(255, 86, 48, 1)", "rgba(7, 191, 88, 1)", "rgba(239, 203, 113, 1.2)"],
          hoverBorderColor: ["rgba(0, 0, 205, 1)", "rgba(255, 86, 48, 1)", "rgba(7, 191, 88, 1)", "rgba(239, 203, 113, 1.2)"],
          data:[
            profiles[0] === undefined ||
              profiles[0] === null
              ? "0"
              : profiles[0].count
              ,
              profiles[1] === undefined ||
              profiles[1] === null
              ? "0"
              : profiles[1].count
              ,
              profiles[2] === undefined ||
              profiles[2] === null
              ? "0"
              : profiles[2].count
              ,
              profiles[3] === undefined
              ? "0"
              : profiles[3].count
          ],
        }
      ],
    };
    setData(chart_data);
  }, [dispatch, profiles]);

  return (<div>{ data && <Bar data={data} width={'100% !important'} height={'50% !important'} /> }</div>);
};
export default Bargraph;
