import {
    authedAxiosInstance
} from "./api.service";

const CustomerService = {
    add: async (customer) => {
        const response = await authedAxiosInstance.post("/customer/add", customer);
        return response.data;
    },
    bulk: async ({file}) => {
        let formData = new FormData();
        formData.append("file", file);
        const response = await authedAxiosInstance.post("/customer/bulk", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        return response.data;
    },
    delete: async ({id}) => {
        const response = await authedAxiosInstance.delete("customer/" + id + "/delete");
        return response.data;
    },
};

export default CustomerService;