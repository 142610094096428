import React, { Fragment, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import CustomerService from "../../api/customer.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomerForm = () => {

  const [customer, setCustomer] = useState({
    name: '',
    email: '',
    phone: ''
  });

  const add = async (event) => {

    event.preventDefault();

    try {
      await CustomerService.add({customer: {
        name: event.target.name.value,
        phone: event.target.phone.value,
        email: event.target.email.value
      }});
      toast.success("Customer created successfully.", {
        position: "top-right",
        autoClose: 5000,
      });
      setCustomer({
        name: '',
        email: '',
        phone: ''
      });
    }
    catch (e) {
      toast.error(e.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }

  };

  return (
    <Fragment>
      <div
        className="container-fluid" >
        <div className="row">
          <div className="col-sm-12 p-0">
            <div className="card mt-4 p-4">
              <h4 className=""><span className="badge badge-primary">Add Single Customer</span></h4>
              <hr />
              <form className="theme-form" onSubmit={add} >
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="col-form-label"> Customer Name</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Name"
                        required
                        id="name"
                        name="name"
                        defaulValue={customer.name}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Customer Email</label>
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        id="email"
                        name="email"
                        defaulValue={customer.email}                        
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Customer Phone Number</label>
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Phone Number"
                        id="phone"
                        name="phone"
                        defaulValue={customer.name}                        
                      />
                    </div>
                  </div>
                </div>

                <div className="col text-center">
                  <button
                    style={{ width: "30%" }}
                    className="btn-lg btn-primary text-center"
                    type="submit"
                  >
                    Save
                    </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerForm;
