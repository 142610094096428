import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import mailchimp from "../../assets/images/mailchimp.png";
import SettingsService from "../../api/settings.service";
import { setMailchimp } from "../../store/loggedInUser.slice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Mailchimp = () => {

  let dispatch = useDispatch();

  const { mailchimp_key } = useSelector((state) => state.authUser);

  const [mailchimpkey, setMailchimpkey] = useState(null);

  useEffect(() => {
    setMailchimpkey(mailchimp_key);
  }, [mailchimp_key])

  const enable = async (event) => {
    event.preventDefault();
    try{
      const api_key = event.target.api_key.value;
      setMailchimp(api_key);
      await SettingsService.enable_mailchimp({api_key: api_key});
      dispatch(setMailchimp(api_key));
      toast.success("Mailchimp enabled successfully.", {
        position: "top-right",
        autoClose: 5000,
      });
    }
    catch(e){
      toast.error(e.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const disable = async (event) => {
    event.preventDefault();
    try{
      await SettingsService.disable_mailchimp();
      dispatch(setMailchimp(''));
      toast.success("Mailchimp disabled successfully.", {
        position: "top-right",
        autoClose: 5000,
      });
      setMailchimp('');
    }
    catch(e){
      toast.error(e.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <Fragment>

      <div className="container-fluid mt-4 p-4 ">
        <div className="card ">
          <div className="card-body">

            <div className="text-center">
              <img
                className="img-responsive"
                src={mailchimp}
                alt="mailchimplogo"
              />
              <h6>
                Status:
                  <span
                  className={
                    mailchimp_key !== ""
                      ? "badge badge-success mr-2 ml-2"
                      : "badge badge-danger mr-2 ml-2"
                  }
                >{mailchimp_key !== "" ? "enabled" : "disabled"}</span>
              </h6>
            </div>

            <div className="col-sm-12">
                  
                  

              {mailchimp_key === "" ? (
                <form onSubmit={enable}>
              <div className="form-group">
                <label className="col-form-label pt-0">API Key</label>
                <input className="form-control" type="text" name="api_key" defaultValue={mailchimpkey}
                />
                {/* {errors.email && 'Email is required'} */}
              </div>
                <div className="form-group form-row mt-3 mb-0">
                  <button className="btn btn-primary btn-block" type="submit">Enable</button>
                </div>
                </form>
              ) : (
                <form onSubmit={disable}>
              <div className="form-group">
                <label className="col-form-label pt-0">API Key</label>
                <input className="form-control" type="text" name="api_key" defaultValue={mailchimpkey}
                />
                {/* {errors.email && 'Email is required'} */}
              </div>
                  <div className="form-group form-row mt-3 mb-0">
                    <button className="btn btn-danger btn-block" type="submit">Disable</button>
                  </div>
                  </form>
                )
              }

             


            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
};

export default Mailchimp;
