import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  customers: []
};

const customerTable = createSlice({
  name: "customerTable",
  initialState,
  reducers: {
    addCustomer(state, action) {
      state.customers.push(action.payload);
    },
    removeCustomer(state, action) {
        var index = state.customers.indexOf(action.payload);
        if (index !== -1) {
            state.customers.splice(index, 1);
        }
    },
  },
});

export const { addCustomer, removeCustomer } = customerTable.actions;

export default customerTable.reducer;