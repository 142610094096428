import React from 'react';
import SmsEditor from "./SmsEditor";
import EmailEditor from "./EmailEditor";

import { useSelector } from 'react-redux';

const Custom = () => {

    const { template } = useSelector((state) => state.authUser);

    return (
        <div
            className="container-fluid">
            <div className="row">
                <div className="col-sm-12 p-0">
                    <div className="card mt-4 p-4">
                        <SmsEditor sms={template ? template.sms : ''} />
                        <hr />
                        <EmailEditor email={template ? template.email : ''} />
                    </div>
                </div>
            </div>
        </div>
    )

};

export default Custom;