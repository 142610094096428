import React, { useEffect, useState } from "react";
import logo from "../assets/images/bizooy-logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthService from "../api/auth.service";
import { setLoggedInUser } from "../store/loggedInUser.slice";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import JwtService from "../api/jwt.service";

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (JwtService.getToken()) {
      AuthService.me().then((loggedInUser) => {
        dispatch(setLoggedInUser(loggedInUser));
        history.push(`${process.env.PUBLIC_URL}/dashboard`);
      });
    }
  }, [dispatch, history]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    try {
      const user = await AuthService.login({
        email: event.target.email.value,
        password: event.target.password.value,
      });
      dispatch(setLoggedInUser(user));
      history.push(`${process.env.PUBLIC_URL}/dashboard`);
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        toast.error(
          "Oppss.. The password is invalid or the user does not have a password."
        );
      }, 200);
      setProcessing(false);
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="card mt-4">
                      <div className="card-body">
                        {!processing && (
                          <div>
                            <div className="text-center">
                              <img src={logo} alt="" />
                            </div>
                            <form
                              className="theme-form"
                              onSubmit={handleSubmit}
                            >
                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  Email
                                </label>
                                <input
                                  className="form-control"
                                  type="email"
                                  name="email"
                                />
                              </div>
                              <div className="form-group">
                                <label className="col-form-label">
                                  Password
                                </label>
                                <input
                                  className="form-control"
                                  type="password"
                                  name="password"
                                />
                                <div>
                                  <p>
                                    <small>
                                      Forgot password?{" "}
                                      <a
                                        className="btn-link text-capitalize"
                                        href="/reset"
                                      >
                                        Click
                                      </a>{" "}
                                      here.
                                    </small>
                                  </p>
                                </div>
                              </div>
                              <div className="form-group form-row mt-3 mb-0">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="submit"
                                >
                                  Login
                                </button>
                              </div>
                              <div className="form-group form-row mt-3 mb-0">
                                <div className="col-sm-12">
                                  <hr />
                                  <div className="mt-2 text-center">
                                    <p>New user?</p>
                                    <a
                                      className="btn btn-outline-primary btn-block"
                                      href="/signup"
                                    >
                                      Sign up
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        )}
                        {processing && (
                          <p>
                            Please wait while we authenticate your
                            information...
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
