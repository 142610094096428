import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import "./dashboard.css";
import Bargraph from "../Bargraph";
import PieChart from "../PieChart";
import DashboardService from '../../api/dashboard.service';

const Dashboard = () => {

  const [dashboard, setDashboard] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {

    async function populate() {
      const data = await DashboardService.get();
      setDashboard(data);
      console.log(data);
    }

    populate();
  }, [dispatch]);

  return (
    <div className="mt-4">
      <div className="ecaps-page-wrapper mt-4" style={{ marginTop: '100px' }}>
        <div className="ecaps-page-content">
          <div className="main-content">
            <div className="container-fluid">
              <div className="card box-margin">
                {dashboard &&

                  <div className="row">
                    <div className="col-md-4">
                      <div className="card box-margin">
                        <div className="card-body">
                          <div className="float-right">
                            <i className="fa fa-pencil text-primary font-30" />
                          </div>

                          <span className="badge badge-primary">
                            New Feedback
                          </span>

                          <h4 className="my-3">
                            {dashboard.reviews === undefined ||
                              dashboard.reviews === null
                              ? "0"
                              : dashboard.reviews.reviewsCreatedThisMonth}
                            <span>
                              <small> created this month.</small>
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card box-margin">
                        <div className="card-body">
                          <div className="float-right">
                            <i className="fa fa-paper-plane font-30" />
                          </div>

                          <span className="badge badge-secondary">
                            New Requests
                          </span>

                          <h4 className="my-3">
                            {dashboard.reviews === undefined ||
                              dashboard.reviews === null
                              ? "0"
                              : dashboard.reviews.monthlyRequests}
                            <span>
                              <small> requests sent this month.</small>
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card box-margin">
                        <div className="card-body">
                          <div className="float-right">
                            <span
                              style={{
                                lineHeight: "1.4",
                                color: "#3c4858",
                                fontWeight: 700,
                                fontSize: "1.5rem",
                              }}
                            >
                              {dashboard.statistics.customers ===
                                undefined ||
                                dashboard.statistics.customers === null
                                ? "0"
                                : dashboard.statistics.ratingAverage}
                            </span>
                            <i className="fa fa-star text-warning font-30" />
                          </div>

                          <span className="badge badge-warning">
                            New Rating Average
                          </span>

                          <h4 className="my-3">
                            <span><small>out of </small> {dashboard.statistics === undefined ? "0" : dashboard.statistics.customers ===
                              undefined ||
                              dashboard.statistics.customers === null
                              ? "0 "
                              : dashboard.statistics.customers[3].count}</span>

                            <span>
                              <small> feedbacks.</small>
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-12 w-100 box-margin height-card">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title text-center"><span className="badge badge-success">Monthly Usage</span></h5>
                          <div className="crm-statement border p-4">
                            <div className="row">
                              <div className="col text-center mb-15">
                                <h6 className="font-14 mb-1">Monthly Requests Quota</h6>

                                <h5 className="mb-3">
                                  <span className="badge badge-dark"> {dashboard.reviews === undefined ||
                                    dashboard.reviews === null
                                    ? " 0"
                                    : dashboard.reviews.monthlyRequests} </span>
                                </h5>
                              </div>
                              <div className="col text-center mb-15">
                                <h6 className="font-14 mb-1">
                                  Max. Number of Monthly Requests
                                </h6>

                                <h5 className="mb-3">
                                  <span className="badge badge-dark">{dashboard.reviews === undefined ||
                                    dashboard.reviews === null
                                    ? " 0"
                                    : dashboard.reviews.monthlyLimit} </span>
                                </h5>
                              </div>
                            </div>

                            <div className="progress-bar-showcase mt-3">
                              <div className="progress">
                                <div
                                  className={`progress-bar ${
                                    (
                                      dashboard.reviews === undefined ||
                                        dashboard.reviews === null
                                        ? "0"
                                        : (dashboard.reviews
                                          .monthlyRequests *
                                          100) /
                                        dashboard.reviews.monthlyLimit >
                                        100
                                    )
                                      ? "bg-danger"
                                      : "bg-success"
                                    }`}
                                  role="progressbar"
                                  style={{
                                    width:
                                      dashboard.reviews === undefined ||
                                        dashboard.reviews === null
                                        ? "0"
                                        : (dashboard.reviews
                                          .monthlyRequests *
                                          100) /
                                        dashboard.reviews.monthlyLimit +
                                        "%"
                                  }}
                                  aria-valuenow={
                                    dashboard.reviews === undefined ||
                                      dashboard.reviews === null
                                      ? "0"
                                      : (dashboard.reviews
                                        .monthlyRequests *
                                        100) /
                                      dashboard.reviews.monthlyLimit +
                                      "%"
                                  }
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <small> {

                                    dashboard.reviews === undefined ||
                                      dashboard.reviews === null
                                      ? "0"
                                      : (dashboard.reviews
                                        .monthlyRequests *
                                        100) /
                                      dashboard.reviews.monthlyLimit +
                                      "%"
                                  } </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-12 w-100 box-margin height-card">
                    {dashboard.statistics === undefined ||
                  dashboard.statistics === null ||
                  dashboard.statistics.customers === null ||
                  dashboard.statistics.customers === undefined ? (
                    <div className="card">
                     <h5 className="card-title text-center"><span className="badge badge-light">Review per Status</span></h5>
                      <div className="card-body">
                        <h5 className="text-center text-secondary font-weight-bold">No data is available.</h5>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-xl-4 pb-4 box-margin height-card ">
                        <div className="card h-100">
                          <div className="card-body">
                          <h5 className="card-title text-center"><span className="badge badge-light">Review per Status</span></h5>

                            {dashboard.statistics.customers.map(
                              (customer) => (
                                <div key={customer.status} className="d-flex flex-row list-group-item align-items-left justify-content-between">
                                  <div className="media-body" >
                                    <h6 className="mx-2 mb-1">
                                      {customer.status}
                                    </h6>
                                  </div>

                                  <div className="amount">
                                    <div className="mb-0 mx-5 font-weight-bold text-dark">
                                      <h6 className="mx-2 mb-1">
                                       <span className="badge badge-dark"> {" "}
                                        {customer.count} </span>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 box-margin height-card">
                        <div className="card" style={{ width: "100%" }}>
                          <div className="card-body">
                            <PieChart statistics={dashboard.statistics.customers} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                    </div>

                   
<div className="col-md-12 box-margin height-card">
      <div className="card w-100">
        <div className="card-body">
        <h5 className="card-title text-center"><span className="badge badge-light">Review per Social Profiles</span></h5>
{typeof dashboard.profiles === 'object' && dashboard.profiles !== null ? (
    
          <div className="row">
            <div className="col-md-4">
              <h5 className="card-title mt-2">Profile Overview</h5>
              <div className="transaction-area">
                <div className="d-flex flex-row list-group-item align-items-center justify-content-between">
                  <div className="media d-flex justify-content-center align-items-center">
                    <div className="icon-section bg-primary-soft">
                      <i className="fa fa-facebook" />
                    </div>
                    <div className="media-body">
                      <h6 className="mx-2 mb-1">Facebook</h6>
                    </div>
                  </div>
                  <div className="amount">
                    <p className="mb-0 font-weight-bold text-dark">
                      {dashboard.profiles === undefined ||
                        dashboard.profiles === null
                        ? "0"
                        : dashboard.profiles[0].count}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row list-group-item align-items-center justify-content-between">
                  <div className="media d-flex justify-content-center align-items-center">
                    <div className="icon-section bg-danger-soft">
                      <i className="fa fa-yelp" />
                    </div>
                    <div className="media-body">
                      <h6 className=" mx-2 mb-1">Yelp</h6>
                    </div>
                  </div>
                  <div className="amount">
                    <p className="mb-0 font-weight-bold text-dark">
                      {dashboard.profiles[1] === undefined ||
                        dashboard.profiles[1] === null
                        ? "0"
                        : dashboard.profiles[1].count}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row list-group-item align-items-center justify-content-between">
                  <div className="media d-flex justify-content-center align-items-center">
                    <div className="icon-section bg-success-soft">
                      <i className="fa fa-google" />
                    </div>
                    <div className="media-body">
                      <h6 className="mx-2 mb-1">Google</h6>
                    </div>
                  </div>
                  <div className="amount">
                    <p className="mb-0 font-weight-bold text-dark">
                      {dashboard.profiles[2] === undefined ||
                        dashboard.profiles[2] === null
                        ? "0"
                        : dashboard.profiles[2].count}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row list-group-item align-items-center justify-content-between">
                  <div className="media d-flex justify-content-center align-items-center">
                    <div className="icon-section bg-warning-soft">
                      <i className="fa fa-external-link" />
                    </div>
                    <div className="media-body">
                      <h6 className="mx-2 mb-1">Custom Link</h6>
                    </div>
                  </div>
                  <div className="amount">
                    <p className="mb-0 font-weight-bold text-dark">
                      {dashboard.profiles[3] === undefined
                        ? "0"
                        : dashboard.profiles[3].count}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 box-margin height-card">
              <div className="card" style={{ width: "100%", maxHeight: '450px' }}>
                <div className="card-body">
                  <Bargraph profiles={dashboard.profiles} />
                </div>
              </div>
            </div>
          </div>
        

    ) : (
      <h5 className="text-center text-secondary font-weight-bold">No data is available.</h5>
    )
    }
    </div>
    </div>
    </div>
</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
