import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import MailchimpService from '../../api/mailchimp.service';
import MailchimpCustomers from './mailchimpCustomers';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Mailchimp = () => {

    const { mailchimp_key } = useSelector((state) => state.authUser);
    const [lists, setLists] = useState(null);

    useEffect(() => {
        //var mailchimp = new Mailchimp(mailchimp_key);

        async function getList() {
            if (mailchimp_key !== "") {
                const list = await MailchimpService.lists();
                setLists(list.lists);
            }
        };

        getList();
    }, [mailchimp_key]);

    const importAll = async (id) => {
        if (window.confirm("Are you sure you want to sync this mailchimp list?")) {
            try {
                toast.info("Processing your request.", {
                    position: "top-right",
                    autoClose: 3000,
                });
                await MailchimpService.sync(id);
                toast.success("Customers imported successfully.", {
                    position: "top-right",
                    autoClose: 5000,
                });
            }
            catch (e) {
                toast.error(e.message, {
                    position: "top-right",
                    autoClose: 5000,
                });
            }
        }
    };

    return (
        <Fragment>
            <div
                className="container-fluid" >
                <div className="row">
                    <div className="col-sm-12 p-0">
                        <div className="card mt-4 p-4">
                            <h4 className=""><span className="badge badge-primary">Add From Mailchimp</span></h4>
                            <hr />
                            {mailchimp_key === "" &&
                                <div>
                                    <h3>Mailchimp not enabled</h3>
                                    <p>Activate your mailchimp integration clicking <Link to='/settings/mailchimp'>here</Link>.</p>
                                </div>
                            }

                            {lists &&
                                lists.map((list, index) => {
                                    return (
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="card card-absolute">
                                                    <div className="card-header bg-primary b-b-info">
                                                        <h5>{list.name}</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <p><b>Contact:</b> {list.contact.company}</p>
                                                        <p><b>Date created:</b> {list.date_created}</p>
                                                        <p><b>Rating:</b> {list.list_rating}</p>
                                                        <p><b>Number of contacts:</b> {list.stats.member_count}</p>

                                                        <button className="btn btn-primary" onClick={() => importAll(list.id)}>Import ALL contacts from list</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="card card-absolute">
                                                    <div className="card-header bg-success b-b-success">
                                                        <h5>Customers</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <MailchimpCustomers id={list.id} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Mailchimp;
