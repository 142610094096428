import React from "react";
import { setSubscriptionType, setToken } from "../store/signupUser.slice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const Subscription = () => {
  let dispatch = useDispatch();

  let history = useHistory();

  const pick = async (type) => {
    dispatch(setToken(""));
    dispatch(setSubscriptionType(type));
    history.push(`${process.env.PUBLIC_URL}/signup/${type}`);
  };

  return (
    <div className="row mt-4">
      <div className="col-md-12">
        <h3>1. Choose a subscription that fit's better for your business</h3>
        <hr />
      </div>

      <div className="col-md-6">
        <div className="card">
          <div className="card-header bg-primary">
            <h5>Free Trial</h5>
          </div>
          <div className="card-body">
            <p className="mb-0"> Limited to 20 requests per month.</p>
          </div>
          <div className="card-footer text-center">
            <button
              className="btn btn-outline-primary"
              onClick={() => pick("free_trial")}
            >
              Select Plan
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card">
          <div className="card-header bg-warning">
            <h5>PRO</h5>
          </div>
          <div className="card-body">
            <p className="mb-0"> Limited to 1000 requests per month.</p>
          </div>
          <div className="card-footer text-center">
            <button
              className="btn btn-outline-warning"
              onClick={() => pick("paid")}
            >
              Select Plan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
