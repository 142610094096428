import React from "react";
import { Pie } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

const PieChart = ({ statistics }) => {

  const dispatch = useDispatch();

  const [data, setData] = useState(null);

  useEffect(() => {

    var per_status = [];

    statistics.forEach((st) => {
      per_status.push(st.count);
    });

    const chart_data = {
      labels: ["request review", "opened", "unopened", "completed"],
      datasets: [
        {
          data: per_status,
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#32CD32"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#32CD32"],
        },
      ],
    };
    setData(chart_data);

  }, [dispatch, statistics]);



  return (<div>{data && <Pie data={data} />} </div>);
};
export default PieChart;
