import {
    authedAxiosInstance
} from "./api.service";

const SettingsService = {
    update_email_template: async (template) => {
        const response = await authedAxiosInstance.post("/settings/custom/email", template);
        return response.data;
    },
    update_sms_template: async (template) => {
        const response = await authedAxiosInstance.post("/settings/custom/sms", template);
        return response.data;
    },
    enable_mailchimp: async(api_key) => {
        const response = await authedAxiosInstance.post("/settings/mailchimp/enable", api_key);
        return response.data;
    },
    disable_mailchimp: async() => {
        const response = await authedAxiosInstance.post("/settings/mailchimp/disable", {});
        return response.data;
    },
    update_social_media: async (social_media) => {
        const response = await authedAxiosInstance.post("/settings/social-profiles", social_media);
        return response.data;
    }
};

export default SettingsService;