import React, { Fragment, useEffect, useState } from 'react';
import man from '../../../assets/images/dashboard/user.png';
import { User } from 'react-feather';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import AuthService from "../../../api/auth.service";
import { destroyLoggedInUser } from '../../../store/loggedInUser.slice';


const UserMenu = ({ history }) => {

    const dispatch = useDispatch();
    const { url } = useSelector((state) => state.authUser);

    const [picture, setPicture] = useState(null);

    const logout = async () => {
        dispatch(destroyLoggedInUser());
        await AuthService.logout();
        history.push(`${process.env.PUBLIC_URL}/login`);
    };

    useEffect(() => {
        //var mailchimp = new Mailchimp(mailchimp_key);
        setPicture(url ? url : man);
    }, [url]);


    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="media align-items-center">
                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={picture} alt="header-user" />
                    {/* <div className="dotted-animation">
                        <span className="animate-circle"></span>
                        <span className="main-circle"></span>
                    </div> */}
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover" >
                    <li><Link to={`${process.env.PUBLIC_URL}/users/edit`}><User />Edit Profile</Link></li>
                    {/* <li><a href="#!"><Lock />Lock Screen</a></li> */}
                    {/* <li><a href="#!"><Settings />Settings</a></li> */}
                    <li><a onClick={logout} href="#!">Log out</a></li>

                </ul>
            </li>
        </Fragment>
    );
};


export default withRouter(UserMenu);