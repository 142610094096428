import {
  createSlice
} from "@reduxjs/toolkit";
import JwtService from "../api/jwt.service";

let initialState = {
  email: '',
  username: '',
  token: '',
  uri: null,
  roles: null,
  isLoading: false,
  isAuthenticated: '',
  status: '',
  passReset: false,
  registered: false,
  userstatus: '',
  mailchimp_key: '',
  subscription_type: '',
  url: '',
  address: null,
  template: null,
  social_profile: {
    "facebook": {
      "url": ""
    },
    "google": {
      "url": ""
    },
    "yelp": {
      "url": ""
    },
    "custom": {
      "url": "",
      "name": ""
    }
  }
};

const authUser = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    setLoggedInUser(state, action) {
      state.email = action.payload.info.email;
      state.username = action.payload.info.displayName;
      state.token = action.payload.token;
      state.userstatus = action.payload.status;
      state.mailchimp_key = action.payload.mailchimp_key;
      state.subscription_type = action.payload.subscription_type;
      state.isLoading = false;
      state.isAuthenticated = true;
      state.status = action.payload.status;
      state.url = action.payload.info.photoURL;
      state.address = action.payload.address;
      state.template = action.payload.template;
      state.social_profile = action.payload.social_profile;
      if (action.payload.social_profile === undefined || action.payload.social_profile === "") {
        state.social_profile = initialState.social_profile;
      }
      if (!JwtService.getToken()) {
        JwtService.saveToken(action.payload.token);
      }
    },
    destroyLoggedInUser(state, action) {
      Object.keys(state).forEach(function (index) {
        state[index] = null;
      });
      JwtService.destroyToken();
    },
    setEmailTemplate(state, action) {
      state.template.email = action.payload;
    },
    setSmsTemplate(state, action) {
      state.template.sms = action.payload;
    },
    setMailchimp(state, action) {
      state.mailchimp_key = action.payload;
    },
    setUserPicture(state, action) {
      state.url = action.payload;
    },
    setSocialProfile(state, action) {
      state.social_profile = action.payload;
    }
  },
});

export const {
  setLoggedInUser,
  destroyLoggedInUser,
  setEmailTemplate,
  setSmsTemplate,
  setMailchimp,
  setUserPicture,
  setSocialProfile
} = authUser.actions;

export default authUser.reducer;