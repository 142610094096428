import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  subscription_type: '',
  token: '',
  sscid: ''
};

const signup = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setSubscriptionType(state, action) {
      state.subscription_type = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    setSSCID(state, action) {
      state.sscid = action.payload;
    },
  },
});

export const { setSubscriptionType, setToken, setSSCID } = signup.actions;

export default signup.reducer;