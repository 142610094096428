import { Home, Users, Settings, UserCheck } from "react-feather";


export const MENUITEMS = [
  {
    title: "Dashboard",
    icon: Home,
    path: "/dashboard",
    type: "link",
    badgeType: "primary",
    active: true,
  },

  {
    title: "Customers",
    icon: Users,
    type: "sub",
    active: false,
    children: [
      {
        path: "/customers/single",
        title: "Add Single Customer",
        type: "link",
      },
      {
        path: "/customers/bulk",
        title: "Add Customers in bulk",
        type: "link",
      },
      {
        path: "/customers/mailchimp",
        title: "Add via MailChimp",
        type: "link",
      },
    ],
  },

  {
    title: "Feedback",
    icon: UserCheck,
    type: "sub",
    active: false,
    children: [
      { path: "/feedback/request-review", title: "Request Feedback", type: "link" },
      { path: "/feedback/unopened", title: "Unopened Feedback", type: "link" },
      { path: "/feedback/opened", title: "Opened Feedback", type: "link" },
      { path: "/feedback/completed", title: "Completed Feedback", type: "link" },
      { path: "/feedback/unsubscribed", title: "Unsubscribed", type: "link" },
    ],
  },

  {
    title: "Settings",
    icon: Settings,
    type: "sub",
    active: false,
    children: [
      { path: "/settings/custom-message", title: "SMS/Email", type: "link" },
      {
        path: "/settings/socialprofiles",
        title: "Social Profiles",
        type: "link",
      },
      {
        path: "/settings/mailchimp",
        title: "Mailchimp Integration",
        type: "link",
      },
    ],
  },
  
];
