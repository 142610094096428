import React from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Pagination from "react-js-pagination";
import { addCustomer, removeCustomer } from "../../store/requestReview.slice";
import FeedbackService from "../../api/feedback.service";
import CustomerService from "../../api/customer.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const Request = () => {


  const todosPerPage = 10;
  const [activePage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [request, setRequest] = useState(1);

  const indexOfLastTodo = activePage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleChange = (event) => {
    var id = event.target.id;
    if (!selectedRows.includes(id)) {
      selectedRows.push(id);
      dispatch(addCustomer(id));
    } else {
      var index = selectedRows.indexOf(id);
      if (index !== -1) {
        selectedRows.splice(index, 1);
      }
      dispatch(removeCustomer(id));
    }
    setSelectedRows(selectedRows => [...selectedRows]);
  };

  const dispatch = useDispatch();


  useEffect(() => {

    async function populate() {
      const reviews = await FeedbackService.request_review();
      setRequest(reviews.customers);
      setSelectedRows([]);
    }

    populate();
  }, [dispatch]);

  const onDelete = async (key) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this customer?",
      icon: "warning",
      dangerMode: true,
      cancelButtonText: "Close",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
    }).then(async (willDelete) => {
      if (willDelete.value !== undefined && willDelete.value) {
        await CustomerService.delete({ id: key }).then(async () => {
          const reviews = await FeedbackService.request_review();
          setRequest(reviews.customers);
        });
      }
    })


  };

  const deleteMany = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete " + selectedRows.length + " customers?",
      icon: "warning",
      dangerMode: true,
      cancelButtonText: "Close",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
    }).then(async (willDelete) => {
      if (willDelete.value !== undefined && willDelete.value) {
        selectedRows.forEach(async (row) => {
          try {
            await CustomerService.delete({ id: row });
            toast.success("Customer deleted successfully.", {
              position: "top-right",
              autoClose: 5000,
            });
            const reviews = await FeedbackService.request_review();
            setRequest(reviews.customers);
          }
          catch (e) {
            toast.error(e.message, {
              position: "top-right",
              autoClose: 5000,
            });
          }
        });

        setSelectedRows([]);
      }
    });
  }

  const askSelected = async () => {

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to send feedback request for " + selectedRows.length + " customers?",
      icon: "warning",
      dangerMode: true,
      cancelButtonText: "Close",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
    }).then(async (willDelete) => {
      if (willDelete.value !== undefined && willDelete.value) {
        try {
          toast.info("Processing your request.", {
            position: "top-right",
            autoClose: 5000,
          });
          await FeedbackService.request_feedback({ customers: selectedRows });
          setTimeout(() => {
            toast.success("Feedback sent successfully.", {
              position: "top-right",
              autoClose: 5000,
            });
          }, 2000);
          setTimeout(async () => {
            var reviews = await FeedbackService.request_review();
            setRequest(reviews.customers);
            setSelectedRows([]);
          }, 5000);
        }
        catch (e) {
          toast.error(e.response.data.error, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      }
    });
  };

  return (
    <div>
      <div className="container-fluid vh-100">
        <div className="row">
          <div className="col-sm-12 p-0">
            <div className="card mt-4 p-4">

              <div>
                <h4 className=""><span className="badge badge-primary">Request Review</span></h4>
                <hr />
                {request && request.length !== 0 ? (



                  <div>

                    <div className="row mb-3 mt-3">
                      <div className="text-center">
                        <Button className="btn btn-primary ml-2 mr-2" disabled={selectedRows.length === 0 || selectedRows === undefined} onClick={askSelected}>Ask Feedback for SELECTED Customers</Button>
                        <Button className="btn btn-primary ml-2 mr-2" disabled={selectedRows.length === 0 || selectedRows === undefined} onClick={deleteMany}>Delete Customers</Button>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead className="table-primary">
                          <tr className="text-center">
                            <th>Select</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">Date Created</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>

                        <tbody>
                          {request.length > 0 &&
                            request
                              .slice(indexOfFirstTodo, indexOfLastTodo)
                              .map((customer) => (
                                <tr className="text-center" key={customer.key}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      name={customer.key}
                                      id={customer.key}
                                      onChange={handleChange}
                                    />
                                  </td>
                                  <td>{customer.name}</td>
                                  <td>{customer.email}</td>
                                  <td>{customer.phone}</td>
                                  <td>{customer.date_created}</td>
                                  <td>
                                    <i
                                      className="fa fa-trash trash"
                                      onClick={(key) => onDelete(customer.key)}
                                    ></i>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="text-center float-right">
                      <Pagination
                        activePage={activePage}
                        totalItemsCount={request.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First"
                        lastPageText="Last"
                      />
                    </div>
                  </div>

                ) : (
                    <h4 className="text-center notAvailableTextStyle">
                      No request review customers available. <br /> <small>Create a new customer accessing the <b className="text-primary">Customers</b> dropdown.</small>
                    </h4>
                  )}

              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Request;
