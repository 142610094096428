import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import AuthService from "../api/auth.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormFields } from "../libs/hooksLib";
import "react-toastify/dist/ReactToastify.css";
import { setLoggedInUser } from "../store/loggedInUser.slice";
import { useParams } from "react-router-dom";
import Payment from "./payment";
import { Alert } from "reactstrap";
import { STATES } from "../data/states";
import InputMask from "react-input-mask";

const User = ({ subscription_type }) => {
  const { token, sscid } = useSelector((state) => state.signup);

  let dispatch = useDispatch();

  const [fields, handleFieldChange] = useFormFields({
    name: "",
    email: "",
    password: "",
    phone: "",
    website: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });

  const [phone, setPhone] = useState("");
  const [usState, setUsState] = useState(null);
  const [subscription, setSubscription] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  let { type } = useParams();

  useEffect(() => {
    if (type !== undefined) {
      var valid = ["free_trial", "paid"];
      if (!valid.includes(type)) {
        setSubscription("");
      }
    }
    setSubscription(type !== undefined ? type : "");
    setUsState(
      STATES.map((state, index) => ({
        label: state.name,
        value: state.abbreviation,
      }))
    );
  }, [subscription_type, type, token, sscid]);

  let history = useHistory();

  const registerAuth = async (e) => {
    e.preventDefault();

    setIsProcessing(true);

    try {
      const user = {
        name: e.target.name.value,
        email: e.target.email.value,
        password: e.target.password.value,
        website: e.target.website.value,
        phone: phone,
        subscription_type:
          subscription === "paid" ? e.target.subscription.value : "",
        payment_token_id: token,
        address: {
          street: e.target.street.value,
          city: e.target.city.value,
          state: e.target.state.value,
          country: e.target.country.value,
          zip_code: e.target.zip.value,
        },
      };

      var id = sscid === null ? "" : sscid === undefined ? "" : sscid;

      await AuthService.register({ business: user, sscid: id }).then(
        async (loggedInUser) => {
          setIsComplete(true);
          dispatch(setLoggedInUser(loggedInUser));
          setTimeout(() => {
            history.push(`${process.env.PUBLIC_URL}/dashboard`);
          }, 5000);
        }
      );
    } catch (err) {
      toast.error(err.message, {
        position: "top-right",
        autoClose: 5000,
      });
      setIsProcessing(false);
    }
  };

  return (
    <div className="card mt-4 p-4">
      <h3>2. Fill your business information</h3>
      <hr />

      <span
        className={
          subscription_type === "free_trial"
            ? "badge badge-primary"
            : "badge badge-warning text-white"
        }
      >
        {subscription_type === "free_trial" ? "FREE TRIAL" : "PRO"}
      </span>
      <p>
        <small>
          Go{" "}
          <a className="text-primary" href="/signup">
            back.
          </a>
        </small>
      </p>

      {!isProcessing && (
        <form className="theme-form" onSubmit={registerAuth}>
          {subscription_type !== "free_trial" && (
            <div className="form-group">
              <label className="col-form-label">
                Choose a subscription option:{" "}
              </label>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input"
                  id="monthly"
                  type="radio"
                  name="subscription"
                  value="monthly"
                  onChange={() => setSubscription("monthly")}
                  defaultChecked={true}
                />
                <label className="custom-control-label" htmlFor="monthly">
                  Monthly - $79.00
                </label>
              </div>

              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input"
                  id="customControlValidation3"
                  name="subscription"
                  type="radio"
                  value="yearly"
                  onChange={() => setSubscription("yearly")}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customControlValidation3"
                >
                  Yearly - $869.00
                </label>
              </div>

              <Payment />
            </div>
          )}

          {((token && token !== "") || subscription_type === "free_trial") && (
            <div>
              <div className="form-group">
                <label className="col-form-label">Business Name</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Business Name"
                  name="name"
                  id="name"
                  value={fields.name}
                  onChange={handleFieldChange}
                  required
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Email</label>
                <input
                  className="form-control"
                  type="email"
                  placeholder="youremail@provider.com"
                  name="email"
                  id="email"
                  value={fields.email}
                  onChange={handleFieldChange}
                  required
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Password</label>
                <input
                  className="form-control"
                  type="password"
                  placeholder="**********"
                  name="password"
                  id="password"
                  value={fields.password}
                  onChange={handleFieldChange}
                  required
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Phone Number</label>
                <InputMask
                  className="form-control"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  mask="+1 (999) 999-9999"
                  maskChar=" "
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Website</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Website"
                  name="website"
                  id="website"
                  value={fields.website}
                  onChange={handleFieldChange}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Address</label>
                <div className="form-row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Street"
                        name="street"
                        id="street"
                        value={fields.street}
                        onChange={handleFieldChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="City"
                        name="city"
                        id="city"
                        value={fields.city}
                        onChange={handleFieldChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        className="form-control"
                        type="text"
                        placeholder="State"
                        name="state"
                        id="state"
                        value={fields.state}
                        onChange={handleFieldChange}
                        required
                      >
                        {usState &&
                          usState.map((state) => {
                            return (
                              <option value={state.value}>{state.label}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="country"
                        value={fields.country}
                        onChange={handleFieldChange}
                        name="country"
                        required
                      >
                        <option value="USA">USA</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Zip Code"
                        name="zip"
                        id="zip"
                        value={fields.zip}
                        onChange={handleFieldChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-sm-12">
                  <button className="btn btn-primary btn-block" type="submit">
                    Sign Up
                  </button>
                </div>
                <div className="col-sm-12 mt-3">
                  <hr />
                  <div className="text-center mt-2 m-l-20">
                    Are you already user?  
                    <a className="btn-link text-capitalize" href="/login">
                      Login
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      )}

      {isProcessing && !isComplete && (
        <Alert color="info" className="dark">
          Please wait, processing your information.
        </Alert>
      )}

      {isComplete && (
        <div>
          <Alert color="success" className="dark">
            User created successfully. Redirecting you to dashboard...
          </Alert>
        </div>
      )}
    </div>
  );
};

export default User;
