import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormFields } from "../../libs/hooksLib";
import { useDispatch } from "react-redux";
import { setSmsTemplate } from "../../store/loggedInUser.slice";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SettingService from "../../api/settings.service";


const Sms = ({sms}) => {

  const [fields, handleFieldChange] = useFormFields({
    template: sms,
  });

  let dispatch = useDispatch();

  

  const smsstate = async (e) => {
    e.preventDefault();
    if (fields.template.length < 100) {
      await SettingService.update_sms_template({template: fields.template});
      toast.success("Custom SMS updated successfully.", {
        position: "top-right",
        autoClose: 5000,
      });
      dispatch(setSmsTemplate(fields.template));
    } else {
      toast.error("Sms cannot be greater than 100 characters", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <div className="mt-4" style={{ marginTop: "40px"}}>
      <div className="form-group mx-4">
      <h4 className=""><span className="badge badge-primary">Custom SMS Message</span></h4>
        <textarea
          className="form-control"
          rows="3"
          id="template"
          value={fields.template}
          onChange={handleFieldChange}
          required
        ></textarea>
        <small>Limited to 100 characters</small>
        <div>
          <small>Leave it blank to use the original template</small>
        </div>
        <div>
          <small>
            The link for feedback will always come at the end of the message
          </small>
        </div>
      </div>
      
      <button
        className="btn btn-primary mx-4"
        type="submit"
        onClick={(e) => smsstate(e, "sms")}
      >
        Save
      </button>
    </div>
  );
};

export default Sms;
