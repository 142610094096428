import { combineReducers } from 'redux';

import Customizer from './customizer.reducer';
import authUser from '../store/loggedInUser.slice';
import dashboardReducer from './dashboard.reducer';
import signup from '../store/signupUser.slice';

const reducers = combineReducers({
    authUser,
    dashboardReducer,
    Customizer,
    signup
});

export default reducers;
