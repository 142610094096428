import {
    authedAxiosInstance
} from "./api.service";

const DashboardService = {
    get: async () => {
        const response = await authedAxiosInstance.get("/dashboard");
        return response.data;
    },
};

export default DashboardService;