import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import { setToken } from "../store/signupUser.slice";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "black",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#87bbfd",
      },
    },
    invalid: {
      iconColor: "black",
      color: "black",
    },
  },
};

const BizooyCard = () => {
  let dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const handleChanges = async (event) => {
    if (event.complete) {
      if (!stripe || !elements) {
        return;
      }

      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card);
      if (result.error) {
        console.log(result.error.message);
      } else {
        setToken(result.token.id);
      }

      if (error) {
        elements.getElement("card").focus();
        return;
      }

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (payload.error) {
        setError(payload.error);
      } else {
        dispatch(setToken(payload.paymentMethod.id));
      }
    } else if (event.error) {
      // show validation to customer
    }
  };

  return (
    <div>
      <label>Credit Card</label>
      <CardElement options={CARD_OPTIONS} onChange={handleChanges} />

      {error && <p className="text-danger">{JSON.stringify(error)}</p>}
    </div>
  );
};

export default BizooyCard;
