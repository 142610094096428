import React, { useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux**
import { Provider } from 'react-redux';
import App from "./components/app";

//settings

import Unsubscribe from './components/feedback/unsubscribed';
import SocialProfiles from './components/settings/SocialProfiles';

//review
import Request from './components/feedback/request-review';
import Opened from './components/feedback/opened';
import CompletedReview from './components/feedback/completed-review';


import Unopened from './components/feedback/unopened';

import SmsEditor from './components/settings/Custom';
import Login from './auth/login';

//customer route
import CustomerForm from './components/customers/CustomerForm';
import CustomerBulk from './components/customers/CustomerBulk';
import mailchimp from './components/customers/mailchimp';
// Import custom Components 


import Mailchimp from './components/settings/Mailchimp';


import Dashboard from './components/dashboard/dashboard.js';

import UserEdit from './components/users/edit';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import LoginWithBgImg from './pages/loginWithBgImg';
import LoginWithVideo from './pages/loginWithVideo';
import Signup from './auth/signup';
import SignupWithImg from './pages/signupWithImg';
import SignupWithVideo from './pages/signupWithVideo';
import UnlockUser from './pages/unlockUser';
import ForgetPwd from './pages/forgetPwd';
import ResetPwd from './pages/resetPwd';
import ResetPwdWithEmail from './auth/reset';
import ComingSoon from './pages/comingsoon';
import ComingSoonImg from './pages/comingsoonImg';
// import ComingSoonVideo from './pages/comingsoonVideo';
import Maintenance from './pages/maintenance';
import Error400 from './pages/errors/error400';
import Error401 from './pages/errors/error401';
import Error403 from './pages/errors/error403';
import Error404 from './pages/errors/error404';
import Error500 from './pages/errors/error500';
import Error503 from './pages/errors/error503';

import card from './components/cards/basicCards';

import { store, persistor } from './store/configureStore';
//config data
import configDB from './data/customizer/config'
import { PersistGate } from 'redux-persist/integration/react'

function Root() {
    const abortController = new AbortController();

    useEffect(() => {
        const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version;

        document.body.classList.add(layout);

        console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
        console.disableYellowBox = true;

        return function cleanup() {
            abortController.abort();
        }


    }, [abortController]);

    return (
        <div className="App">
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter basename={`/`}>
                        <ScrollContext>
                            <Switch>
                                <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
                                <PublicRoute restricted={true} path={`${process.env.PUBLIC_URL}/login`} component={Login} />
                                <PrivateRoute path={`${process.env.PUBLIC_URL}/pages/loginWithBgImg`} component={LoginWithBgImg} />
                                <PrivateRoute path={`${process.env.PUBLIC_URL}/pages/loginWithVideo`} component={LoginWithVideo} />
                                <Route path={`${process.env.PUBLIC_URL}/signup/:type`} component={Signup} />
                                <Route path={`${process.env.PUBLIC_URL}/signup`} component={Signup} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/signupWithImg`} component={SignupWithImg} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/signupWithVideo`} component={SignupWithVideo} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/unlockUser`} component={UnlockUser} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/forgetPwd`} component={ForgetPwd} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/resetPwd`} component={ResetPwd} />
                                <Route path={`${process.env.PUBLIC_URL}/reset`} component={ResetPwdWithEmail} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/comingsoon`} component={ComingSoon} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonImg`} component={ComingSoonImg} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/card`} component={card} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403} />

                                <Route path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503} />

                                <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                    return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />)
                                }} />

                                <Fragment>
                                    <App>
                                        <PrivateRoute path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} exact />
                                        <PrivateRoute path={`${process.env.PUBLIC_URL}/customers/single`} component={CustomerForm} />
                                        <PrivateRoute path={`${process.env.PUBLIC_URL}/customers/bulk`} component={CustomerBulk} />
                                        <PrivateRoute path={`${process.env.PUBLIC_URL}/customers/mailchimp`} component={mailchimp} />
                                        <PrivateRoute path={`${process.env.PUBLIC_URL}/feedback/unopened`} component={Unopened} />
                                        <PrivateRoute path={`${process.env.PUBLIC_URL}/feedback/request-review`} component={Request} />
                                        <PrivateRoute path={`${process.env.PUBLIC_URL}/feedback/opened`} component={Opened} />
                                        <PrivateRoute path={`${process.env.PUBLIC_URL}/feedback/completed`} component={CompletedReview} />
                                        <PrivateRoute path={`${process.env.PUBLIC_URL}/feedback/unsubscribed`} component={Unsubscribe} />
                                        <PrivateRoute path={`${process.env.PUBLIC_URL}/settings/custom-message`} component={SmsEditor} />
                                        <PrivateRoute path={`${process.env.PUBLIC_URL}/settings/mailchimp`} component={Mailchimp} />
                                        <PrivateRoute path={`${process.env.PUBLIC_URL}/settings/socialprofiles`} component={SocialProfiles} />
                                        <PrivateRoute path={`${process.env.PUBLIC_URL}/users/edit`} component={UserEdit} />
                                    </App>
                                </Fragment>

                                <Route path="*" component={Error404} exact />
                            </Switch>
                        </ScrollContext>

                    </BrowserRouter>
                </PersistGate>
            </Provider>

        </div>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();