import React, { Fragment, useEffect, useState } from "react";
import logo from "../assets/images/bizooy-logo.png";
import { useSelector, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import User from "./user";
import Subscription from "./subscription";
import { setSSCID } from "../store/signupUser.slice";

const Signup = () => {
  const { sscid } = useSelector((state) => state.signup);

  let dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  const affiliate_sscid = queryParams.get("sscid");
  let { type } = useParams();

  const { subscription_type } = useSelector((state) => state.signup);
  const [subscription, setSubscription] = useState("");

  useEffect(() => {
    if (sscid === undefined || sscid === "" || sscid === null) {
      dispatch(setSSCID(affiliate_sscid));
    }

    if (type !== undefined) {
      var valid = ["free_trial", "paid"];
      if (!valid.includes(type)) {
        setSubscription("");
      }
    }
    setSubscription(type !== undefined ? type : "");
  }, [subscription_type, type, sscid, dispatch, affiliate_sscid]);

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* <!-- sign up page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-sm-12 p-0">
                <div className="text-center">
                  <img src={logo} alt="" />
                </div>
                {subscription === "" && (
                  <div className="card mt-4">
                    <div className="card-body">
                      <Subscription />

                      <hr />
                      <p className="text-center">
                        Already have an account?{" "}
                        <a className="text-primary" href="/login">
                          Login
                        </a>
                      </p>
                    </div>
                  </div>
                )}
                {subscription && subscription !== "" && (
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <User subscription_type={subscription} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <!-- sign up page ends--> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Signup;
