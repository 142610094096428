import React from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { deleteRequest } from "../../actions/review.action";
import Pagination from "react-js-pagination";
import FeedbackService from "../../api/feedback.service";

const Opened = () => {

  const todosPerPage = 7;
  const [activePage, setCurrentPage] = useState(1);
  const [opened, setOpened] = useState([]);

  const indexOfLastTodo = activePage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const dispatch = useDispatch();

  useEffect(() => {

    async function populate() {
      const reviews = await FeedbackService.opened();
      setOpened(reviews.customers);
    }

    populate();
  }, [dispatch]);

  const onDelete = (key) => {
    dispatch(deleteRequest(key));
  };

  return (
    <div>
      
        <div className="container-fluid vh-100">
          <div className="row">
            <div className="col-sm-12 p-0">
              <div className="card mt-4 p-4">


                <div>
                  <h4 className=""><span className="badge badge-primary">Opened</span></h4>
                  <hr />

                  {opened && opened.length !== 0 ? (
                    <div>
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead className="table-primary">
                            <tr className="text-center">
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Phone Number</th>
                              <th scope="col">Date Opened</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>

                          <tbody>
                            {opened
                              .slice(indexOfFirstTodo, indexOfLastTodo)
                              .map((customer) => (
                                <tr className="text-center" key={customer.key}>
                                  <td>{customer.name}</td>
                                  <td>{customer.email}</td>
                                  <td>{customer.phone}</td>
                                  <td>{customer.date_feedback_opened}</td>
                                  <td>
                                    <i
                                      className="fa fa-trash trash"
                                      onClick={(key) => onDelete(customer.key)}
                                    ></i>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="text-center float-right">
                        <Pagination
                          activePage={activePage}
                          totalItemsCount={opened.length}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                          firstPageText="First"
                          lastPageText="Last"
                        />
                      </div>
                    </div>
                  ) : (
                      <h4 className="text-center notAvailableTextStyle">
                        No opened feedback available.
            </h4>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </div>
  );
};

export default Opened;
