import React, { Fragment, useState, useEffect } from 'react';
import man from '../../../assets/images/dashboard/user.png'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import { useSelector } from 'react-redux';

const UserPanel = () => {
    
    const {url, email, username} = useSelector((state) => state.authUser);

    const [picture, setPicture] = useState(null);

    useEffect(() => {
        //var mailchimp = new Mailchimp(mailchimp_key);
        setPicture(url ? url : man);
    }, [url]);
    
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={picture} alt="#" />
                    <div className="profile-edit">
                        <Link to={`${process.env.PUBLIC_URL}/users/edit`}>
                            <Edit />    
                        </Link>
                    </div>
                </div>
                <h6 className="mt-3 f-14">{username}</h6>
                <p>{email}</p>
            </div>
        </Fragment>
    );
};

export default UserPanel;