import {
    axiosInstance,
    authedAxiosInstance
} from "./api.service";
import jwt from "./jwt.service";

const AuthService = {
    login: async ({
        email,
        password
    }) => {
        const response = await axiosInstance.post("/auth/login", {
            email,
            password,
        });
        authedAxiosInstance.defaults.headers[
            "Authorization"
        ] = `Bearer ${response.data.token}`;
        jwt.saveToken(response.data.token);
        return response.data;
    },
    logout: async () => {
        const response = await authedAxiosInstance.get("/auth/logout");
        return response.data;
    },
    reset_password: async (email) => {
        const response = await axiosInstance.post("auth/forgotPassword", email);
        return response.data;
    },
    me: async () => {
        const response = await authedAxiosInstance.get("auth/me");
        return response.data;
    },
    register: async (business, sscid) => {
        const response = await axiosInstance.post("auth/register", business, sscid);
        return response.data;
    }
};

export default AuthService;